@import './var.module.scss';

.datePicker {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background-color: 'white';

  line-height: 1.25em;
  min-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  // text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 4px;
  border: 1px solid $colorGrey6;
  padding: 8px 14px;
  height: inherit;
  &:focus {
    border: 1px solid $colorBlue5;
    outline: none;
  }

  &:hover:not(:focus) {
    border-color: black;
  }

  &::placeholder {
    color: $colorGrey6;
  }
}

.datePickerDesktopTable {
  font-weight: 400;
  font-size: 12px;
  background-color: 'white';
  line-height: 1.25em;
  min-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  // text-align: center;
  width: 100%;
  height: 100%;
  min-height: 80 !important;

  position: relative;
  border-radius: 4px;
  border: 1px solid $colorGrey6;
  padding: 8px 14px;

  &:focus {
    border: 1px solid $colorBlue5;
    outline: none;
  }

  &:hover:not(:focus) {
    border-color: black;
  }

  &::placeholder {
    color: $colorGrey6;
  }
}

.react-datepicker-ignore-onclickoutside {
  width: 300px;
}

.datePickerMobileTable {
  line-height: 1.25em;
  // min-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  height: 100%;
  width: 100%;
  position: relative;
  padding: 8px 14px;
  height: inherit;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $colorGrey6;
  &:focus {
    // border: 1px solid $colorBlue5;
    outline: none;
  }

  &:hover:not(:focus) {
    border-color: black;
  }

  &::placeholder {
    color: $colorGrey6;
  }
}

.datePickerError {
  border-color: red;
}
.datePickerThickTable {
  height: 80px;
}
.datePickerWrapper .datePicker {
  // font-size: 12px;
  // text-align: center;
}

.datePickerWrapperTableDefault .datePicker {
  font-size: 12px;
  text-align: center;
}

.datePickerPopper {
  z-index: 100;
}

.datePickerCalendar {
  z-index: 19999;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px,
    rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  transform-origin: center top;
  outline: 0px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;

  :global {
    .react-datepicker__navigation {
      padding-top: 5px;
      margin: 0.5rem;

      .react-datepicker__navigation-icon--previous {
        margin-right: 5px;
      }

      .react-datepicker__navigation-icon--next {
        margin-left: 5px;
      }

      &:hover {
        border: none;
        border-radius: 50% !important;
        background-color: $colorGrey3;
      }
    }

    .react-datepicker__month-container,
    .react-datepicker__time-container {
      .react-datepicker__header {
        background-color: white;
        border-bottom: none;
        line-height: 2.25rem;
        font-weight: normal;

        .react-datepicker__current-month,
        .react-datepicker-time__header {
          font-weight: normal;
        }

        .react-datepicker__day-names > * {
          width: 2.25rem;
          line-height: 2.25rem;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .react-datepicker__time-container,
    .react-datepicker__triangle {
      // display: none;
      // position: absolute;
      // transform: translateX(-50%);
      // z-index: 9999;
    }
    .react-datepicker__time-list-item--disabled,
    .react-datepicker__day--disabled,
    .react-datepicker__month--disabled,
    .react-datepicker__quarter--disabled,
    .react-datepicker__year--disabled {
      color: $colorGrey !important;
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      // border: none;
      border-radius: 50% !important;
      background-color: var(--dark);
      color: var(--white);
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
      // border: none;
      border-radius: 50% !important;
      background-color: var(--dark);
      color: var(--white);
    }

    .react-datepicker__header {
      border-bottom: solid 5px var(--light) !important;
      background: white !important;
    }

    .react-datepicker__current-month {
      color: var(--dark) !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__quarter--selected,
    .react-datepicker__year--selected {
      border: none;
      border-radius: 50% !important;
      background-color: $colorBlue6;
      color: white !important;

      &:hover {
        background-color: $colorBlue7 !important;
        color: white !important;
      }
    }

    .react-datepicker__day,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
      width: 2.25rem;
      line-height: 2.25rem;
      color: $colorGrey2;
      font-size: 0.75rem;

      &:hover {
        border: none;
        border-radius: 50% !important;
        background-color: $colorGrey3;
      }

      &[class$='--today'] {
        font-weight: normal;
        border: 1px solid $colorGrey9 !important;
        border-radius: 50% !important;
        width: 2.25rem;
        height: 2.25rem;
      }
    }

    .react-datepicker__month--selected {
      background-color: $colorBlue6 !important;
      color: white !important;

      &:hover {
        background-color: $colorBlue7 !important;
        color: white;
      }
    }

    .react-datepicker__month-text--keyboard-selected {
      background-color: transparent;
    }

    .react-datepicker__month-text {
      line-height: 2.25rem;
      color: $colorGrey2;
      font-size: 0.75rem;
      border: none;
      border-radius: 18px !important;
      width: 5.5rem;

      &:hover {
        background-color: $colorGrey3;
      }

      &[class$='--today'] {
        font-weight: normal;
        border: 1px solid $colorGrey9 !important;
        height: 2.25rem;
      }
    }
  }
}

.datePickerTime {
  height: 2.25rem !important;
  line-height: 2.25rem;
  color: $colorGrey2;
  font-size: 0.75rem;

  &:hover {
    background-color: $colorGrey3 !important;
  }

  &[class$='--selected'] {
    background-color: $colorBlue6 !important;
    color: white;

    &:hover {
      background-color: $colorBlue7 !important;
      color: white;
    }
  }
}
.disableCalendar {
  display: none;
}

.react-datepicker__input-container {
  display: block;
}
