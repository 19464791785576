.lg-backdrop {
  z-index: 20000 !important;
}
.lg-outer {
  z-index: 20010 !important;
}

.lg-custom-thumbnails {
  &.lg-outer {
    width: auto;
    // Set space for the container to occupy thumbnails
    right: 225px;

    // Add some spacing on the left to match with the right hand side spacing
    left: 10px;

    .lg-thumb-outer {
      // Set the position of the thumbnails
      left: auto;
      top: 0;
      width: 225px;
      bottom: 0;
      position: fixed;
      right: 0;

      // Reset max height
      max-height: none;

      // Customize the layout (Optional)
      background-color: #999;
      padding-left: 5px;
      padding-right: 5px;
      margin: 0 -10px;
      overflow-y: auto;

      // Update transition values
      // By default thumbnails animates from bottom to top
      // Change that from right to left.
      // Also, add a tiny opacity transition to look better
      transform: translate3d(30%, 0, 0);
      opacity: 0;
      will-change: transform opacity;
      transition: transform 0.15s cubic-bezier(0, 0, 0.25, 1) 0s, cubic-bezier(0, 0, 0.25, 1) 0.15s;
    }

    &.lg-thumb-open {
      .lg-thumb-outer {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    // Add hove effect (Optional)
    .lg-thumb-item {
      filter: grayscale(100%);
      will-change: filter;
      transition: filter 0.12s ease-in, border-color 0.12s ease;
      &:hover,
      &.active {
        filter: grayscale(0);
        border-color: #545454;
      }
    }

    .lg-thumb {
      padding: 5px 0;
    }
  }
}

.custom-class-name {
  display: flex;
  flex-flow: row wrap;
}

// a {
//   color:'#333' ,
//   &:hover {
//     color: #545454;
//   }
// }

// .pswp,
// .pswp__scroll-wrap {
//   z-index: 20000 !important;
//   position: fixed;
//   top: 0;
//   right: 0;
// }

// .galleryItemContainer {
//   &:hover {
//     .middle {
//       opacity: 1 !important;
//       -webkit-transition: background-color 2s ease-in, opacity 2s ease-in;
//       transition: background-color 2s ease-in, opacity 2s ease-in;
//     }
//   }
//   &:hover {
//     .image {
//       opacity: 0.3 !important;
//       -webkit-transition: background-color 2s ease-in, opacity 2s ease-in;
//       transition: background-color 2s ease-in, opacity 2s ease-in;
//     }
//   }
// }

// .galleryOverlay {
//   background: white;
//   position: absolute;
//   opacity: 0;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   -webkit-transition: background-color 2s ease-in, opacity 2s ease-in;
//   transition: background-color 2s ease-in, opacity 2s ease-in;
// }

// .galleryItem {
//   position: relative;
//   background-color: white;
// }
.gContainer {
  position: relative;
}
.gImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  // transition: 0.5s ease;
  backface-visibility: hidden;
}

.gMiddle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.gContainer:hover {
  .gImage {
    opacity: 0.5;
  }
}

.gContainer:hover .gMiddle {
  opacity: 1;
}

// in scss, when input checkbox is checked, then its parent class called gContainer will have a blue border

// .gContainer {
//   border: 1px solid transparent;

//   input[type='checkbox'] {
//     &:checked {
//       &:nth-last-of-type(4) {
//         border: 1px solid blue;
//       }
//     }
//   }
// }
// .gContainer {
//   border: 1px solid transparent;

//   .gCheckbox {
//     &:checked {
//       + & {
//         border: 1px solid blue;
//       }
//     }
//   }
// }

.gCheckbox {
  &:checked {
    ~ .gImage {
      border: 1px solid blue;
    }
  }
}

.csv-reader-input .csv-input {
  display: none;
}
