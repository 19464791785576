@media (max-width: 768px) {
  /* Apply styles for screens up to 768px wide */
  .MuiTable-root {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .MuiTableHead-root,
  .MuiTableBody-root,
  .MuiTableRow-root {
    display: block;
  }

  .MuiTableCell-root {
    display: inline-block;
    width: 100%;
  }
}
