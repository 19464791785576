// var.scss
$main: rgb(118, 174, 201);
$light: rgb(174, 221, 243);
$dark: rgb(20, 87, 121);
$contrastText: rgb(245, 243, 241);
$errorColor: #d32f2f;

:export {
  main: $main;
  light: $light;
  dark: $dark;
  contrasttext: $contrastText;
  errorColor: $errorColor;
}

$colorGrey: #70707033;
// $colorGrey2: #707070;
$colorGrey2: #393939;
$colorGrey3: #f2f2f2;
$colorGrey4: #f3f3f3;
$colorGrey5: #fdfdfd;
$colorGrey6: #b7b7b7;
$colorGrey7: #7070701a;
$colorGrey8: #cccccc;
$colorGrey9: #70707080;
$colorGrey10: #fafafa;
$colorYellow: #ffca61;
$colorBlue: #98dff6;
$colorBlue2: #7094b8;
$colorBlue3: #546fc6;
$colorBlue4: #5dc3d4;
$colorBlue5: #76aec9;
$colorBlue6: #1976d2;
$colorBlue7: #1565c0;
$colorRed: #ff6464;
$colorRed2: #e87779;
$colorRed3: #e65100;
$colorGreen2: #85bcb6;
$colorGreen: #6bc950;
$colorPurple: #7c4dff;
$colorOrange: #ff7600;

$fontGrey: #636363;

$fontSizeBaseRegular: 16px;
$Fontsize2XS: 0.5625rem;
$FontsizeXS: 0.75rem;
$FontsizeS: 0.875rem;
$FontsizeM: 1rem;
$FontsizeL: 1.125rem;
$FontsizeXL: 1.25rem;
$Fontsize2XL: 1.5rem;
$HeadingFontsize: 2rem;
$HeadingFontsizeL: 2.25rem;

$fontWeightLig: 300;
$fontWeightReg: 400;
$fontWeightMed: 500;
$fontWeightBold: 700;
$fontWeightBlack: 900;

$shadow: 0px 3px 6px #00000029;
$shadowReverse: 0px -3px 6px #00000029;
