.logoImage {
  width: 80%;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  // background-color: white;
}

.afa {
  svg {
    transition: all 0.2s ease-in-out;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &::after {
    content: attr(data-code);
    min-width: 30px;
    text-align-last: center;
    border: 1px solid;
    background-color: white;
    margin-top: 10px;
    margin-left: -28px;
    font-size: 12px;
  }
}

.headerLogoImage {
  // position: fixed;
  width: 150px;
  top: 0;
  left: 0;
  margin-bottom: 30;
  width: inherit;
  // background-color: white;
}

.sideMenuHeader {

  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
}

.navItemButton {
  height: 100%;
  width: 0;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  img {
    display: none;
  }
}

.navItem {
  .navDescription {
    transition: all 0.2s ease-in-out;
    padding-right: 1rem;
  }

  .navItemHeader {
    transition: all 0.2s ease-in-out;
  }

  &:hover[data-selected='true'] {
    .navItemButton {
      width: 50px;
    }

    .navDescription {
      transform: translateX(-55px);
    }

    .navItemHeader {
      transform: translateX(-45px);
    }

    img {
      display: block;
    }

    svg {
      width: 0;
    }
  }
}

.navItem {
  position: relative;
  .borderBottom {
    width: 0;
    transition: width 0.3s linear;
  }

  &:hover .borderBottom {
    width: 100%;
  }
}
