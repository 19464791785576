.homeBtn {
    background-image: url('../img/home.svg');
    width: 100%;
    height: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
}

.notificationBtn {
    background-image: url('../img/setting.svg');
    width: 100%;
    height: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
}
